import { useState } from "react";
import "../stylesheets/VisualArt.css";
import { Image } from "../components/visual_art/Image";
import { srcImgs } from "../data/imgData";
import { Modal } from "./visual_art/Modal";
import { NavLink } from "react-router-dom";
import { FaArrowCircleLeft, FaEye } from "react-icons/fa";

function VisualArt() {
  const [imageSrc, setImageSrc] = useState(null);
  const [isModalHidden, setIsModalHidden] = useState(true);

  const currentIndex = srcImgs.findIndex((img) => img === imageSrc);

  const openImg = (e) => {
    setImageSrc(e.target.src);
    setIsModalHidden(false);
  };

  const closeImg = (e) => {
    setIsModalHidden(true);
  };

  const prev = () => {
    const prevIndex = (currentIndex - 1 + srcImgs.length) % srcImgs.length;
    setImageSrc(srcImgs[prevIndex]);
    console.log("this should pass the url for the prev imgSrc");
  };

  const next = () => {
    const nextIndex = (currentIndex + 1) % srcImgs.length;
    setImageSrc(srcImgs[nextIndex]);
    console.log("this should pass the url for the next imgSrc");
  };

  return (
    <div className="main-gy-container">
      {/* <div className="decoration_words"> */}
      <>
      <h3 className="item item_3 collage-h1">
        cRaZy_CollaGe_World <FaEye></FaEye></h3>
      </>        
      {/* </div> */}

      <div className="gy-container">
        {srcImgs.map((img, index) => {
          return <Image key={index} src={img} openImg={openImg} />;
        })}
      </div>
      <Modal
        isHidden={isModalHidden}
        imageSrc={imageSrc}
        closeImg={closeImg}
        handlePrevious={prev}
        handleNext={next}
      />
      <button className="btn-back">
        <NavLink to="/">
          <FaArrowCircleLeft />
        </NavLink>
      </button>
      {/* <div className="buttons">
        <button>
          <a
            href="https://opensea.io/charly_bgood"
            rel="noreferrer"
            target="_BLANK"
          >
            <i className="fa-solid fa-cannabis"></i>

            <i className="fa-solid fa-cannabis"></i>
          </a>
        </button>
        <button>
          <a
            href="https://giphy.com/channel/charly-_bgood"
            rel="noreferrer"
            target="_BLANK"
          >
            <i className="fa-solid fa-cannabis"></i>
            Giphy
            <i className="fa-solid fa-cannabis"></i>
          </a>
        </button>
      </div> */}
    </div>
  );
}

export default VisualArt;
