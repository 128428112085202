import "../stylesheets/SinglePages.css";
import { NavLink } from "react-router-dom";
import { FaArrowCircleLeft, FaCode, FaFileCode } from "react-icons/fa";
import DevProjects from "./DevProjects";

function WebSites() {
  return (
    <>
      <p className="p_description">
        <FaCode></FaCode> On-demand <code>Websites</code><FaFileCode></FaFileCode>
      </p>
      <div className="img_container">
        <div className="decoration_words">
          <DevProjects
            className="item item_1"
            projectLink="https://estarsiempreseguros.com.ar/"
            projectName="#brokerCompany"
          />
          <DevProjects
            className="item item_2"
            projectLink="https://www.reinamomo.art"
            projectName="#reinaMomoArteSana"
          />
        </div>
      </div>
      <button className="btn-back">
        <NavLink to="/DevPortfolio">
          <FaArrowCircleLeft />
        </NavLink>
      </button>
    </>
  );
}

export default WebSites;
