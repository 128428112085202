import { FaVolumeUp, FaVolumeMute } from "react-icons/fa";

function VolumeControls({ volSlider, maxMinVol, volOn, muteVol, unMuteVol }) {
  return (
    <>
      <span id="volDown">-</span>
      <input
        type="range"
        id="volumeSlider"
        min="0"
        max="100"
        step="0.1"
        ref={volSlider}
        onChange={maxMinVol}
      />
      <span id="volUp">+</span>
      <button
        onClick={volOn ? muteVol : unMuteVol}
        className="volume-onOff"
        id="volOnOff"
      >
        {volOn ? <FaVolumeUp /> : <FaVolumeMute />}
      </button>
    </>
  );
}

export default VolumeControls;
