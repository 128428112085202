import "../stylesheets/Music.css";
import { useRef, useState } from "react";
import songs from "../data/songs_db";
import SongList from "./music_player/SongList";
import PlayerControls from "./player_controls/PlayerControls";
import VolumeControls from "./player_controls/VolumeControls";
// import { Link } from "react-router-dom";
import { FaArrowCircleDown, FaArrowCircleLeft } from "react-icons/fa";
import { NavLink } from "react-router-dom";

function Music() {
  const player = useRef(null);
  const playerMain = useRef(null);
  const songList = useRef(null);
  const volSlider = useRef(null);
  const progressBar = useRef(null);
  const [currentSong, setCurrentSong] = useState();
  const [isPlaying, setIsPlaying] = useState(false);
  const [volOn, setVolOn] = useState(true);
  const [timeUpdate, setTimeUpdate] = useState();
  const [timeDuration, setTimeDuration] = useState();

  //  audio control buttons
  const playPause = () => {
    if (!isPlaying) {
      setIsPlaying(true);
      if (currentSong) {
        player.current.play();
      } else {
        selectThisSong(songs[0]);
      }
    } else {
      setIsPlaying(false);
      player.current.pause();
    }
  };

  const stopSong = () => {
    player.current.pause();
    player.current.currentTime = 0;
    setIsPlaying(false);
    progressBar.current.value = "0";
    setCurrentSong("");
  };

  const prevSong = () => {
    const currentIndex = songs.indexOf(currentSong);
    let prevIndex = currentIndex - 1;
    if (prevIndex < 0) {
      prevIndex = songs.length - 1;
    }
    const prevTrack = songs[prevIndex];
    setCurrentSong(prevTrack);
    player.current.src = require(`../music/${prevTrack}.mp3`);
    setIsPlaying(true);
    player.current.play();
  };

  const nextSong = () => {
    const currentIndex = songs.indexOf(currentSong);
    const nextIndex = (currentIndex + 1) % songs.length;
    const nextTrack = songs[nextIndex];
    setCurrentSong(nextTrack);
    player.current.src = require(`../music/${nextTrack}.mp3`);
    setIsPlaying(true);
    player.current.play();
  };

  // select song from list
  const selectThisSong = (song) => {
    setIsPlaying(true);
    setCurrentSong(song);
    player.current.src = require(`../music/${song}.mp3`);
    player.current.play();
    window.scrollTo({ top: playerMain.current.offsetTop, behavior: "smooth" });
  };

  const scroll = () => {
    window.scrollTo({ top: songList.current.offsetTop, behavior: "smooth" });
  };

  // volume controls
  const maxMinVol = () => {
    player.current.volume = volSlider.current.value / 100;
  };

  const muteVol = () => {
    player.current.volume = 0;
    setVolOn(false);
  };

  const unMuteVol = () => {
    player.current.volume = 1;
    setVolOn(true);
  };

  // Progress bar un updated time display

  const calcTime = (secs) => {
    const minutes = Math.floor(secs / 60);
    const seconds = Math.floor(secs % 60);
    const retSecs = seconds < 10 ? `0${seconds}` : `${seconds}`;
    return `${minutes}:${retSecs}`;
  };

  const clickProgressBar = (e) => {
    const newTime =
      (e.nativeEvent.offsetX / progressBar.current.offsetWidth) *
      player.current.duration;
    player.current.currentTime = newTime;
  };

  const handleProgressChange = () => {
    const duration = player.current.duration;
    const currentTime = player.current.currentTime;
    if (currentTime === 0) {
      progressBar.current.value = currentTime;
    } else {
      progressBar.current.value = (currentTime / duration) * 100;
    }
    // progressBar.current.value = (currentTime / duration) * 100;
    updateTime();
  };

  const updateTime = () => {
    setTimeUpdate(calcTime(player.current.currentTime));
    if (player.current.readyState > 0) {
      setTimeDuration(calcTime(player.current.duration));
    }
  };

  return (
    <>
      <div className="player" id="player_main" ref={playerMain}>
        <div className="player_one">
          <div className="radio">
            <i className="music_author">
              Music by
              <a
                href="https://soundcloud.com/charly-bgood"
                target="_blank"
                rel="noopener noreferrer"
              >
                Charly BGood
              </a>
            </i>
            <div
              className={currentSong ? "player_tv tv_on" : "player_tv tv_off"}
            ></div>
            <audio
              id="player"
              ref={player}
              onEnded={() => nextSong()}
              onTimeUpdate={handleProgressChange}
            ></audio>
            <div id="controls">
              <p>{currentSong ? `#${currentSong}` : ""}</p>
              <PlayerControls
                playPause={playPause}
                stopSong={stopSong}
                prevSong={prevSong}
                nextSong={nextSong}
                isPlaying={isPlaying}
              />
              <div className="progress_section">
                <span className="durStart">
                  {currentSong ? timeUpdate : "-"}
                </span>
                <progress
                  max="100"
                  id="progress-bar"
                  ref={progressBar}
                  className="progress"
                  onPointerDown={clickProgressBar}
                ></progress>
                <span className="durEnd">
                  {currentSong ? timeDuration : "-"}
                </span>
              </div>
              <div className="volume">
                <VolumeControls
                  maxMinVol={maxMinVol}
                  muteVol={muteVol}
                  unMuteVol={unMuteVol}
                  volSlider={volSlider}
                  volOn={volOn}
                />
              </div>
              <button className="btn-down" onClick={scroll}>
                <FaArrowCircleDown />
              </button>
            </div>
          </div>
          <div id="songList" ref={songList}>
            <SongList selectThisSong={selectThisSong} />
            <button className="btn-back_up">
              <NavLink to="/">
                <FaArrowCircleLeft />
              </NavLink>
            </button>
          </div>
        </div>
      </div>
    </>
  );
}

export default Music;
