import Girl from "../img/gallery/Girl.jpg";
import sigil from "../img/gallery/sigil.jpg";
import charlyvirtual from "../img/gallery/charlybgoodvirtual.png";
import marilyn from "../img/gallery/MARILYN.jpg";
import armani from "../img/gallery/armani.png";
import VanGhog from "../img/gallery/vangogh.png";

export const srcImgs = [
  "https://media.giphy.com/media/V75H9GKVV33eWvJjeo/giphy.gif",  
  "https://media.giphy.com/media/vuDebpxeqF95dy4k7v/giphy.gif",
  Girl,
  "https://media1.giphy.com/media/exPvEKmXHb6dWiTgQv/giphy.gif",
  "https://media.giphy.com/media/cLP818k392JZpIFATT/giphy.gif",
  marilyn,
  "https://media.giphy.com/media/RwRWKEIFp8rtwn3SpC/giphy.gif",
  charlyvirtual,
  VanGhog,
  "https://media.giphy.com/media/RL0VClRYDycJtJtrnu/giphy.gif",  
  "https://media.giphy.com/media/xXI7vCRLLviHeO41TF/giphy.gif",  
  "https://media.giphy.com/media/6V7gDOure95uLJkwAg/giphy.gif",
  "https://media.giphy.com/media/3o6gaUNh6CJD3rOQOk/giphy.gif",
  "https://media3.giphy.com/media/PFQYIEQJdKxjabBIFE/giphy.gif",  
  sigil,
  armani,
];
