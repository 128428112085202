import { NavLink } from "react-router-dom";

export default function PageNotFound() {
  return (
    <div className="decoration_words">
      <NavLink className="item item_3" to="./">
        - 404 PAGE NOT FOUND -
      </NavLink>
    </div>
  );
}
