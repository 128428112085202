import Song from "./Song";
import { v4 as uuidv4 } from "uuid";
import songs from "../../data/songs_db";

function SongList({selectThisSong}) {
  return (
    <>
      {songs.map((song) => (
        <Song
          song={song}
          key={uuidv4()}
          selectThisSong={() => selectThisSong(song)}
        />
      ))}
    </>
  );
}

export default SongList;
