const songs = [
  "NewBegining",
  "Pianotec",
  "Gravity",
  "Medussa",
  "Strange Summer",
  "Indie-gest",
  "tanHop",
  "Rare Manifestum",
  "Poppa America",
  "Semiotico",
  "Planeta_yo",
  "Working Class",
  "Nos Vamos",
];

export default songs;
