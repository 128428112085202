import "../stylesheets/SinglePages.css";
import { NavLink } from "react-router-dom";
import { FaArrowCircleLeft, FaCode } from "react-icons/fa";
import DevProjects from "./DevProjects";

function ReactProjects() {
  return (
    <>
      <p className="p_description">
      <FaCode></FaCode> Web apps and projects using <code>Frameworks</code> <FaCode></FaCode>
      </p>
      <div className="img_container">
        <div className="decoration_words">
          <DevProjects
            className="item item_1"
            projectLink="https://classic-todo-app.vercel.app/"
            projectName="#TodoApp"
          />
          <DevProjects
            className="item item_2"
            projectLink="https://test-app-mocha-three.vercel.app/"
            projectName="#User_Cards"
          />
          <DevProjects
            className="item item_3"
            projectLink="https://no-state-node-delta.vercel.app/Welcome"
            projectName="#FirebaseLogin"
          />
          <DevProjects
            className="item item_1"
            projectLink="https://img-gallery-react.vercel.app/"
            projectName="#imgGallery"
          />          
        </div>
      </div>
      <button className="btn-back">
        <NavLink to="/DevPortfolio">
          <FaArrowCircleLeft />
        </NavLink>
      </button>
    </>
  );
}

export default ReactProjects;
