import "../stylesheets/SinglePages.css";
import { NavLink } from "react-router-dom";
import { FaArrowCircleLeft, FaPalette, FaPaintRoller } from "react-icons/fa";
import DevProjects from "./DevProjects";

function SinglePages() {
  return (
    <>
      <p className="p_description">
        <FaPalette></FaPalette> Web Design | <code>Html/Css/Js</code> <FaPaintRoller></FaPaintRoller>
      </p>
      <div className="img_container">
        <div className="decoration_words">
          <DevProjects
            className="item item_2"
            projectLink="https://charlybgood.github.io/freecodecamp/webdesign/2-surveyform/index.html"
            projectName="#surveyForm"
          />
          <DevProjects
            className="item item_1"
            projectLink="https://charlybgood.github.io/freecodecamp/webdesign/3-productlandingpage/index.html"
            projectName="#landingPage"
          />
          <DevProjects
            className="item item_3"
            projectLink="https://charlybgood.github.io/google-homepage-clone/"
            projectName="#GiphyAPI"
          />
          <DevProjects
            className="item item_2"
            projectLink="https://charlybgood.github.io/odin-recipes/"
            projectName="#CookBook"
          />
          <DevProjects
            className="item item_1"
            projectLink="https://charlybgood.github.io/freecodecamp/webdesign/4-technicaldocumentationpage/index.html"
            projectName="#technicalDoc"
          />
        </div>
      </div>
      <button className="btn-back">
        <NavLink to="/DevPortfolio">
          <FaArrowCircleLeft />
        </NavLink>
      </button>
    </>
  );
}

export default SinglePages;
