import React from "react";
import "../stylesheets/Footer.css";
// import { FaGithub, FaTwitter, FaYoutube } from "react-icons/fa";

function Footer() {
  return (
    <footer className="smedia">
      <p>
        ~{" "}
        <a
          href="https://github.com/CharlyBGood"
          target="_blank"
          rel="noreferrer"
        >
          Charly BGood
        </a>
        {" "}~
      </p>
    </footer>
  );
}

export default Footer;
