import { FaPlay, FaPause, FaBackward, FaStop, FaForward } from "react-icons/fa";

function PlayerControls({
  playPause,
  prevSong,
  stopSong,
  nextSong,
  isPlaying,
}) {
  return (
    <>
      <div className="controls_wrapper">
        <button onClick={prevSong}>
          <FaBackward />
        </button>
        <button className="playBtn" onClick={playPause}>
          {isPlaying ? <FaPause /> : <FaPlay />}
        </button>
        <button onClick={stopSong}>
          <FaStop />
        </button>
        <button onClick={nextSong}>
          <FaForward />
        </button>
      </div>
    </>
  );
}

export default PlayerControls;
