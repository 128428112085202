import "../stylesheets/Contact.css";
import {
  FaGithub,
  FaYoutube,
  FaInstagram,
  FaTwitter,
  FaVimeo,
  FaSoundcloud,
  FaBandcamp,
} from "react-icons/fa";

function Contact() {
  return (
    <div className="smedia-contact">
      <a href="https://github.com/CharlyBGood" target="_blank" rel="noreferrer">
        {/* <i className="fa-brands fa-github"></i> */}
        <FaGithub />
      </a>
      <a href="https://bit.ly/BGoodYouTube" target="_blank" rel="noreferrer">
        {/* <i className="fab fa-youtube"></i> */}
        <FaYoutube />
      </a>
      <a href="https://vimeo.com/charlybgood" target="_blank" rel="noreferrer">
        <FaVimeo />
      </a>
      <a
        href="https://www.instagram.com/charlsbgood/"
        target="_blank"
        rel="noreferrer"
      >
        <FaInstagram />
      </a>
      <a
        href="https://twitter.com/CharlybGoodM"
        target="_blank"
        rel="noreferrer"
      >
        <FaTwitter />
      </a>
      <a
        href="https://soundcloud.com/charly-bgood"
        target="_blank"
        rel="noreferrer"
      >
        <FaSoundcloud />
      </a>
      <a
        href="https://charlybgood1.bandcamp.com/"
        target="_blank"
        rel="noreferrer"
      >
        <FaBandcamp />
      </a>
    </div>
  );
}

export default Contact;
