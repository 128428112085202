import "../stylesheets/DevPortfolio.css";
import "../stylesheets/Content.css";
import { NavLink } from "react-router-dom";
import ImgCenter from "../img/img-planetavacio.png";
import { FaArrowCircleLeft, FaJs, FaPalette, FaFileCode, FaFolderOpen, FaCode } from "react-icons/fa";

function DevPortfolio() {
  return (
    <>
      <div className="img_container">
        <div className="decoration_words">
          <NavLink className="item item_1" to="./WebApps">          
          <FaFolderOpen></FaFolderOpen>_fun_JavaScript<FaJs></FaJs>
          </NavLink>
          <NavLink className="item item_2" to="./ReactProjects">
            <FaFolderOpen></FaFolderOpen>_frameworks<FaCode></FaCode>
          </NavLink>
          <img className="img" src={ImgCenter} alt="Charly BGood Logo" />
          <NavLink className="item item_1" to="./WebDesign">
          <FaFolderOpen></FaFolderOpen>_webDesign<FaPalette></FaPalette>
          </NavLink>
          <NavLink className="item item_2" to="./WebSites">
          <FaFolderOpen></FaFolderOpen>_webPages<FaFileCode></FaFileCode>
          </NavLink>
        </div>
      </div>
      <button className="btn-back">
        <NavLink to="/">
          <FaArrowCircleLeft />
        </NavLink>
      </button>
    </>
  );
}

export default DevPortfolio;
